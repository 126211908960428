import { useEffect, useState } from "react";
import CardImovel from "../../components/CardImovel";
import Header from "../../components/Header";
import HomeBanner from "../../components/HomeBanner";
import "./style.css";
import { FaBold, FaClock, FaFileAlt, FaUser } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { service } from "../../service/service";
import ReactLoading from "react-loading";

const Home = () => {
  const [imoveisParaExibir, setImoveisParaExibir] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const api = service();
  const navigate = useNavigate();

  const getImoveis = async () => {
    setLoading(true);
    try {
      const response = await api.getAllRealState();
      if (response && !response.status && response.length > 0) {
        setImoveisParaExibir(response);
      } else {
        console.error("No properties found or error in API response.");
        setImoveisParaExibir([]); // Handle the empty case
      }
    } catch (error) {
      console.error("Error fetching properties:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getImoveis();
  }, []);

  const updateImoveisParaExibir = () => {
    const tamanho = window.innerWidth;
    if (tamanho > 750) {
      setImoveisParaExibir(imoveisParaExibir.slice(0, 6));
      console.log("Tamanho:", tamanho, "e", imoveisParaExibir);
    } else {
      setImoveisParaExibir(imoveisParaExibir.slice(0, 2));
    }
  };

  useEffect(() => {
    document.title = "Diego Oliveira | Início";
    if (imoveisParaExibir.length > 0) {
      updateImoveisParaExibir();
    }

    window.addEventListener("resize", updateImoveisParaExibir); // Adiciona o event listener para o resize
    return () => {
      window.removeEventListener("resize", updateImoveisParaExibir); // Remove o event listener ao desmontar o componente
    };
  }, [imoveisParaExibir]);
  return (
    <>
      {loading && (
        <div className="loading-overlay">
          <ReactLoading type="spin" color="#7E6E12" height={80} width={80} />
        </div>
      )}
      <HomeBanner />
      <section className="imoveis-disponiveis">
        <h2>Imóveis Disponíveis</h2>
        {imoveisParaExibir.length > 0 && imoveisParaExibir ? (
          <>
            <div className="cards">
              {imoveisParaExibir &&
                imoveisParaExibir.map((imovel: any) => (
                  <Link
                    to={{
                      pathname: `/detalhe/${imovel.id}`,
                    }}
                    state={imovel}
                    style={{ textDecoration: "none" }}
                  >
                    <CardImovel key={imovel.id} imovel={imovel} />
                  </Link>
                ))}
            </div>
            <button
              onClick={() => navigate("/real-estate")}
              className="ver-mais"
            >
              Ver mais
            </button>
          </>
        ) : (
          <p>Nenhum imóvel disponível no momento</p>
        )}
      </section>
      <section className="faixa">
        <h2>Encontre seu imóvel dos sonhos</h2>
      </section>

      <section className="balao-sobre">
        <div className="balao-sobre-imagem">
          <img
            src="https://images.pexels.com/photos/3555615/pexels-photo-3555615.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            alt="Imagem de uma casa"
          />
        </div>
        <div className="balao-sobre-texto">
          <h2>Quem somos</h2>
          <p>
            Somos uma empresa especializada em encontrar o imóvel perfeito para
            você. Com uma equipe de corretores altamente qualificados, estamos
            prontos para atender todas as suas necessidades.
          </p>
          <Link className="ver-mais" to={"/about"}>
            Saiba mais
          </Link>
        </div>
      </section>

      <section className="why-choose-us">
        <h2>Por que escolher seu imóvel conosco?</h2>
        <div className="cards-wcu">
          <div className="card-wcu">
            <FaUser size={48} />
            <h3>Atendimento personalizado</h3>
            <p>Foco nas suas necessidades, com soluções personalizadas.</p>
          </div>
          <div className="card-wcu">
            <FaClock size={48} />
            <h3>Experiência no Mercado</h3>
            <p>Com a nossa experiência, garantimos a melhor negociação.</p>
          </div>
          <div className="card-wcu">
            <FaFileAlt size={48} />
            <h3>Transparência em todas as Etapas</h3>
            <p>Negociações claras e seguras, sem surpresas.</p>
          </div>
          <div className="card-wcu">
            <FaBold size={48} />
            <h3>Agilidade no Atendimento</h3>
            <p>Rapidez na resposta e eficiência para fechar negócios.</p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
